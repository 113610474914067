// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as H5 from "../../../styleguide/components/Heading/H5.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CategoryResourcesScss from "./CategoryResources.scss";

var css = CategoryResourcesScss;

function CategoryResources(props) {
  var category = props.category;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H1.make, {
                      className: css.categoryTitle,
                      children: category.title
                    }),
                JsxRuntime.jsx("p", {
                      children: category.description,
                      className: css.categoryDescription
                    }),
                Belt_Array.map(props.resources, (function (resource) {
                        return JsxRuntime.jsx(A.make, {
                                    href: "/resources/" + (resource.categorySlug + ("/" + resource.slug)),
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("img", {
                                                        className: css.resourceImage,
                                                        src: resource.resourceImage
                                                      }),
                                                  className: css.itemImageContainer
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx(H5.make, {
                                                          className: css.itemHeading,
                                                          children: resource.title
                                                        }),
                                                    JsxRuntime.jsx("p", {
                                                          children: resource.resourceSummary,
                                                          className: css.itemDescription
                                                        })
                                                  ],
                                                  className: css.itemDetailContainer
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx(Button.make, {
                                                        size: "SM",
                                                        color: "Teal",
                                                        visuallyDisabled: resource.pdfFile.length === 0,
                                                        children: "Download"
                                                      }),
                                                  className: css.downloadButtonContainer
                                                })
                                          ],
                                          className: css.categoryItemContainer
                                        })
                                  }, ID.toString(resource.id));
                      })),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Pagination.make, {
                            currentPage: props.currentPage,
                            totalPages: props.totalPages,
                            onPageClick: props.onPageClick
                          }),
                      className: css.paginationContainer
                    })
              ]
            });
}

var make = CategoryResources;

export {
  css ,
  make ,
}
/* css Not a pure module */
