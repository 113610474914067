// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TopBarScss from "./TopBar.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = TopBarScss;

function isSelectedCategory(itemName, selectedCategoryDetail) {
  if (selectedCategoryDetail !== undefined) {
    return selectedCategoryDetail.navigationName === itemName;
  } else {
    return false;
  }
}

function TopBar(props) {
  var selectedCategory = props.selectedCategory;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: Belt_Array.mapWithIndex(props.categories, (function (index, category) {
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Link.make, {
                                              href: category.slug === "all-resources" ? "/resources" : "/resources/" + category.slug,
                                              className: css.linkItem,
                                              children: category.navigationName
                                            }),
                                        className: Cx.cx([
                                              css.linkContainer,
                                              category.slug === selectedCategory ? css.underlinedLink : ""
                                            ])
                                      }, category.navigationName + String(index));
                          })),
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = TopBar;

export {
  css ,
  isSelectedCategory ,
  make ,
}
/* css Not a pure module */
