// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var topMargin = Cx.cx([
      "mt-28",
      "lg:mt-32"
    ]);

export {
  topMargin ,
}
/* topMargin Not a pure module */
