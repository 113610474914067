// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H5 from "../../../styleguide/components/Heading/H5.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as LineSeperator from "./LineSeperator.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PopularResourceItem from "./PopularResourceItem.res.js";
import * as PopularResourcesScss from "./PopularResources.scss";

var css = PopularResourcesScss;

function PopularResources(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H5.make, {
                      className: css.title,
                      children: "Popular Resources"
                    }),
                JsxRuntime.jsx(LineSeperator.make, {}),
                Belt_Array.mapWithIndex(props.popular, (function (index, resource) {
                        return JsxRuntime.jsx(PopularResourceItem.make, {
                                    title: resource.title,
                                    path: "/resources/" + (resource.categorySlug + ("/" + resource.slug))
                                  }, resource.title + ("-popular-" + String(index)));
                      }))
              ],
              className: css.container
            });
}

var make = PopularResources;

export {
  css ,
  make ,
}
/* css Not a pure module */
